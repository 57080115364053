import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { selectUser } from '../ngrx/data.reducer';

@Injectable({
  providedIn: 'root',
})
export class FanGuard {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(): boolean {
    let userDetails: any;
    this.store
      .select(selectUser)
      .pipe(take(1),distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe((user) => {
        userDetails = user;
      });
    if (userDetails?.userTypeId == 1) {
      this.router.navigate(['/']);
      false;
    }
    return true;
  }
}
