import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (environment.sentryDsn) {
  console.log('Sentry enabled');
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [Sentry.captureConsoleIntegration(), Sentry.browserTracingIntegration()],
    release: environment.appVersion,
    environment: 'production',
    tracesSampleRate: 0.8,
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
