import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class OnlineStatusInterceptor implements HttpInterceptor {
  constructor(
    private onlineStatusService: OnlineStatusService,
    private toastr: ToastrService,
    private store: Store,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.onlineStatusService.getStatus() === OnlineStatusType.OFFLINE) {
      LoaderService.loader.next(false);
      this.toastr.error(
        'You are currently offline. Please check your internet connection.',
        'Offline',
      );
      return throwError({ error: 'No internet connection' });
    } else {
      return next.handle(request);
    }
  }
}
