import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class guestUserSignIn {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const guestUser = localStorage.getItem('guestDetails');
    if (guestUser) {
      const user = JSON.parse(guestUser);
      if (user?.isNewUser) {
        this.router.navigateByUrl('/signup');
        return false;
      }
    }
    return true;
  }
}
