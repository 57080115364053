import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class guestUserSignUp {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const guestUser = localStorage.getItem('guestDetails');
    if (guestUser) {
      const user = JSON.parse(guestUser);
      if (!user?.isNewUser) {
        this.router.navigateByUrl('/signin');
        return false;
      }
    }
    return true;
  }
}
