import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { provideCloudinaryLoader } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  Router,
  UrlSerializer,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { Store, StoreModule } from '@ngrx/store';
import { OnlineStatusModule } from 'ngx-online-status';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { ToastrModule } from 'ngx-toastr';
import { Observable, of, tap } from 'rxjs';
import * as Sentry from "@sentry/angular";
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { addCategory } from './ngrx/data.action';
import {
  AlternateLoaderReducer,
  CollectionSearchingReducer,
  carouselReducer,
  categoryNameReducer,
  categoryReducer,
  flowReducer,
  ipStateReducer,
  searchStateReducer,
  sideBarReducer,
  sliderReducer,
  sortCollectionReducer,
  userReducer,
} from './ngrx/data.reducer';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { CommonService } from './services/common.service';
import { HelperService } from './services/helper.service';
import { HttpService } from './services/http.service';
import { LoaderService } from './services/loader.service';
import { MetaService } from './services/meta.service';
import { MixpanelService } from './services/mixpanel.service';
import { ProductService } from './services/product.service';
import { ResizeService } from './services/resize.service';
import { ScriptService } from './services/script.service';
import { ScrollService } from './services/scroll.service';
import { SeoService } from './services/seo.service';
import { AppSignalRService } from './services/signalR.service';
import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';
import { LowerCaseUrlSerializer } from './tsExtras/LowerCaseUrlSerializer';
import { OnlineStatusInterceptor } from './tsExtras/internet.interceptor';
import { RetryInterceptor } from './tsExtras/retry.interceptor';
export const initializeApp = (
  httpClient: HttpClient,
  store: Store,
): (() => Observable<any>) => {
  return () => {
    const categoryRoutes = window.localStorage.getItem('categoryRoutes');
    const categoryVersion = window.localStorage.getItem('categoryVersion');
    if (categoryRoutes && categoryVersion == '0.1') {
      return of(null);
    } else {
      LoaderService.loader.next(true);
      return httpClient.get(`${environment.apiUrl}api/home/categories-v2`).pipe(
        tap(async (response: any) => {
          const processCategory = (category: any) => {
            const formatName = (name: string) =>
              name
                ?.replace(/\s+/g, '-')
                .replace(/-+&-+/g, '&')
                ?.toLowerCase() || '';
            const formattedCategory = {
              ...category,
              path: formatName(category?.name),
              subCategory: (category?.subCategory || []).map(
                (subCategory: any) => ({
                  ...subCategory,
                  path: formatName(subCategory?.name),
                }),
              ),
            };
            const newSubCategory = {
              categoryId: null,
              id: null,
              imagePath: null,
              isSelected: true,
              name: 'All',
              path: '',
              position: null,
            };
            let newSubCategories;
            if (formattedCategory.subCategory?.length > 0) {
              newSubCategories = [
                newSubCategory,
                ...formattedCategory.subCategory,
              ];
            }
            const newCategory = {
              ...formattedCategory,
              subCategory: newSubCategories,
            };

            store.dispatch(addCategory({ category: newCategory }));
            return newCategory;
          };
          const routes = response.modelList.map(processCategory);
          localStorage?.setItem('collectionRedirectPath', routes?.[0]?.path);
        }),
      );
    }
  };
};
const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      inMemoryScrollingFeature,
      withPreloading(QuicklinkStrategy),
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideCloudinaryLoader('https://res.cloudinary.com/dmdawjgs8/'),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [HttpClient, Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OnlineStatusInterceptor,
      multi: true,
    },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleAuthKey, {
              oneTapEnabled: false,
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAuthKey),
          },
        ],
        onError: (err) => console.error(err),
      } as SocialAuthServiceConfig,
    },
    TokenService,
    ResizeService,
    ScrollService,
    ProductService,
    ScriptService,
    UserService,
    MixpanelService,
    MetaService,
    AuthInterceptorService,
    AuthService,
    CommonService,
    HelperService,
    HttpService,
    LoaderService,
    SeoService,
    AppSignalRService,
    importProvidersFrom(
      QuicklinkModule,
      OnlineStatusModule,
      StoreModule.forRoot({
        category: categoryReducer,
        user: userReducer,
        sideBar: sideBarReducer,
        slider: sliderReducer,
        alternateLoader: AlternateLoaderReducer,
        sortCollection: sortCollectionReducer,
        flow: flowReducer,
        carousel: carouselReducer,
        searching: CollectionSearchingReducer,
        name: categoryNameReducer,
        search: searchStateReducer,
        ip: ipStateReducer,
      }),
      ToastrModule.forRoot({
        timeOut: 10000,
        positionClass: 'toast-bottom-left',
        maxOpened: 1,
        autoDismiss: true,
        preventDuplicates: true,
        includeTitleDuplicates: true,
      }),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
};
