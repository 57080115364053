import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { updateUserData } from '../ngrx/data.action';
import { selectUser } from '../ngrx/data.reducer';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private isTokenRefresh = false;
  private user$ = this.store.select(selectUser);
  private user;
  constructor(
    private tokenService: TokenService,
    private store: Store,
  ) {
    this.user$
    .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
    .subscribe((user) => {
      this.user = user;
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.user) {
      req = this.addTokenHeader(req, this.user.token);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (!this.isTokenRefresh) {
            this.isTokenRefresh = true;
            return this.handle401Error(req, next);
          }
        }
        return throwError(error);
      }),
    );
  }

  public handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.tokenService.refreshToken().pipe(
      switchMap((data) => {
        const updatedUserDetails = {
          ...this.user,
          token: data.token,
          refreshToken: data.refreshToken,
        };
        this.store.dispatch(updateUserData({ user: updatedUserDetails }));
        this.isTokenRefresh = false;
        const newAuthReq = this.addTokenHeader(request, data.token);
        return next.handle(newAuthReq);
      }),
      catchError((error) => {
        console.error('Interceptor error: ', error);
        // this.helper.signOut();
        return throwError(error);
      }),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
