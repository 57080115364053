import { Routes } from '@angular/router';
import { CreatorGuard } from './guards/creator.guard';
import { FanGuard } from './guards/fan.guard';
import { guestUserSignIn } from './guards/guestUserSignIn.guard';
import { guestUserSignUp } from './guards/guestUserSignUp.guard';
import { InnerGuard } from './guards/inner.guard';
export const routes: Routes = [
  {
    path: 'signin',
    data: { auth: true },
    canActivate: [InnerGuard, guestUserSignIn],
    loadChildren: () =>
      import('./view/auth/signin/signIn.routes').then((m) => m.Signin_Routes),
  },
  {
    path: 'signup',
    data: { auth: true },
    canActivate: [InnerGuard, guestUserSignUp],
    loadChildren: () =>
      import('./view/auth/signup/signUp.routes').then((m) => m.Signup_Routes),
  },
  {
    path: 'reset-password',
    data: { auth: true },
    canActivate: [InnerGuard],
    loadChildren: () =>
      import('./view/auth/reset-password/reset.routes').then(
        (m) => m.Reset_Routes,
      ),
  },
  {
    path: 'forgot-password',
    data: { auth: true },
    canActivate: [InnerGuard],
    loadChildren: () =>
      import('./view/auth/forgot-password/forgot-password.routes').then(
        (m) => m.Forgot_Routes,
      ),
  },
  {
    path: 'otp',
    data: { auth: true },
    canActivate: [InnerGuard],
    loadChildren: () =>
      import('./view/auth/otp/otp.routes').then((m) => m.Otp_Routes),
  },
  {
    path: 'creator',
    
    canActivate: [CreatorGuard],
    loadChildren: () =>
      import('./view/creator/creator.routes').then((m) => m.Creator_Routes),
  },
  {
    path: 'fan',
    
    canActivate: [FanGuard],
    loadChildren: () =>
      import('./view/fan/fan.routes').then((m) => m.Fan_Routes),
  },
  {
    path: 'email-verify/:otp/:id',
    
    loadChildren: () =>
      import('./shared/verify-email/verify-email.routes').then((m) => m.Verify_Email_Routes),
  },
  {
    path: '',
    
    loadChildren: () =>
      import('./view/home/home.routes').then((m) => m.Home_Routes),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
