import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, throwError, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  mergeMap,
  retryWhen,
  switchMap,
  takeUntil,
  timeout,
} from 'rxjs/operators';
import { updateUserData } from '../ngrx/data.action';
import { selectUser } from '../ngrx/data.reducer';
import { HelperService } from '../services/helper.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  private timeoutDuration: number = 50000;
  private maxTries: number = 3;
  private user$ = this.store.select(selectUser);
  private user;
  // List of endpoints to exclude from retry logic
  private excludedEndpoints: string[] = [
    'video',
    'media',
    'createMentorSlots',
    'bookingSlots',
  ];
  private destroy$ = new Subject<void>();
  constructor(
    private tokenService: TokenService,
    private store: Store,
    private helper: HelperService,
  ) {
    this.user$
    .pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
    .subscribe((user) => {
      this.user = user;
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const retryCondition = (error: HttpErrorResponse, retryCount: number) => {
      if (error.status === 400) {
        return throwError(error);
      }

      if (
        retryCount < this.maxTries &&
        (this.isNetworkError(error) || this.isInternalError(error))
      ) {
        return timer(1500);
      }

      return throwError(error);
    };
    const shouldExclude = this.excludedEndpoints.some(endpoint => request.url.toLowerCase().includes(endpoint.toLowerCase()));
    if (shouldExclude) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      timeout(this.timeoutDuration),
      retryWhen((errors: Observable<any>) =>
        errors.pipe(mergeMap(retryCondition)),
      ),
      catchError((error: HttpErrorResponse) =>
        this.handleRetryError(error, request, next),
      ),
    );
  }

  private handleError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    if (error.status === 401) {
      console.error(
        `Request failed with status code ${error.status}: ${error}`,
      );
      return this.handle401Error(request, next);
    } else {
      console.error(`Unexpected error: ${error}`);
    }

    return throwError(error);
  }

  private handleRetryError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    if (error.status === 401) {
      console.error(`Retry failed with status code ${error.status}: ${error}`);
      return this.handle401Error(request, next);
    } else {
      console.error(`Unexpected error: ${error}`);
    }

    return throwError(error);
  }

  private isNetworkError(error: HttpErrorResponse): boolean {
    return error.error instanceof ErrorEvent;
  }

  private isInternalError(error: HttpErrorResponse): boolean {
    return !error.status || error.status === 500;
  }
  
  public handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.tokenService.refreshToken().pipe(
      switchMap((data) => {
        const updatedUserDetails = {
          ...this.user,
          token: data.token,
          refreshToken: data.refreshToken,
        };
        this.store.dispatch(updateUserData({ user: updatedUserDetails }));
        const newAuthReq = this.addTokenHeader(request, data.token);
        return next.handle(newAuthReq);
      }),
      catchError((error) => {
        if (
          error?.url?.includes('refreshToken') &&
          error?.error?.status == 400
        ) {
          this.helper.signOut();
        }
        console.error('Interceptor error checking: ', error);
        return throwError(error);
      }),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
