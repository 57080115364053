import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { selectUser } from '../ngrx/data.reducer';

@Injectable({
  providedIn: 'root',
})
export class InnerGuard {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    let userDetails: any;
    this.store
      .select(selectUser)
      .pipe(
        take(1),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe((user) => {
        if (
          user &&
          (user?.userType?.toLowerCase() == 'fan' ||
            user?.userType?.toLowerCase() == 'creator' ||
            user?.userType?.toLowerCase() == 'both')
        ) {
          userDetails = user;
        }
      });

    if (userDetails) {
      const routePath =
        '/' +
        userDetails?.userType?.toLowerCase() +
        '/' +
        (userDetails?.userType?.toLowerCase() == 'creator'
          ? 'dashboard'
          : 'chats');
      this.router.navigate([routePath]);
      return false;
    } else {
      return true;
    }
  }
}
